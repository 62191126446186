/*scss/organisms/_headerMain.scss*/

.headerMain {
  //height: 430px;
  background: #000 url("../img/print.png") center;
  background-size: cover;
  text-align: center;
  padding: 0 15px;
}

.headerMain__inner {
  @extend .grid-row;
}

.appLogo{
  //max-width: 370px;
  margin: auto;
}

/*
.headerMain__left{
}
.headerMain__right{
  .navMain{
      display: inline-block;
  }
}*/
