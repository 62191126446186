/* scss/siteButtons/_links.scss */

a {
  color: inherit;
  text-decoration: none;
}

.link{
  color: get-color('color12');
  transition: all .25s;
}

.link-1{
  color: get-color('color8');
  font-size: 12px;
}

.link-2{
  color: get-color('color9');
  font-size: 12px;
  font-weight: 700;
  font-family: $font2;
  &:hover{
    color: darken(get-color('color9'), 20%);
  }
}

.link-3{
  color: get-color('color10');
  font-size: 16px;
  &:hover{
    color: darken(get-color('color10'), 20%);
  }
  &:after{
    content: "\f054";
    font-family: "FontAwesome";
    display: inline-block;
    margin-left: 5px;
    font-size: 10px;
    vertical-align: middle;
  }
  &.link-3-alt{
    &:after{
      display: none;
    }
  }
}


.link-4{
  color: get-color('color10');
  font-size: 18px;
  font-weight: 500;
  font-family: $font2;
  &:hover{
    color: darken(get-color('color10'), 20%);
  }
  &.link-4-alt{
    color: get-color('color9');
    &:hover{
      color: darken(get-color('color9'), 20%);
    }

  }
}



