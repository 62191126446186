/*scss/variables/_colors.scss*/

$gradient1: linear-gradient(to right, #ffaf47 0%, #ed8c0b 100%);

$colors: (
        'color1': #F4F7FA,
        'color2': #DFE6EE,
        'color3': #B0BAC5,
        'color4': #8090A5,
        'color5': #667587,
        'color6': #3E4956,
        'color7': #4A494A,
        'color8': #16181D,
        'color9': #2E2F83,
        'color10': #00AEEF,
        'color11': #ED8B0A,
        'color12': #172559,
        'color13': #0395FF,
        'color14': #15153B,
);

@each $id, $color in $colors {
  .text-#{$id} {
    color: $color;
  }
  .bg-#{$id} {
    background-color: $color;
  }
  .color {
    .bg-#{$id} + .color__footer {

      .color__scss {
        &:before {
          content: "$#{$id}";
          display: block;
          position: relative;
        }
      }
      .color__color {
        &:before {
          content: "#{$color}";
          display: block;
          position: relative;
        }
      }

    }
  }

}

.gradient1 {
  background: $gradient1;
}

.colors {
  @extend .grid-row;
}

.color {
  border: 1px solid #ccc;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px;
  text-align: center;
  background: #fff;
  border-radius: 2px;
  &.color-gradient {

    .color__content {
      width: 200px;
    }
  }
}

.color__content {
  width: 120px;
  height: 100px;
  border-radius: 2px;
}

.color__footer {
  font-size: 14px;
  margin-bottom: 0;
  padding-top: 8px;
}

.colorFixed-open {
  overflow: hidden;
  .colorFixed {
    right: 0;
  }
}

.colorFixed {
  position: fixed;
  z-index: 3000;
  top: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.57);
  width: 150px;
  padding: 10px;
  background: #eee;
  height: 100%;
  right: -150px;
  transition: right .25s;
  .color {
    float: none;
    &.color-gradient {

      .color__content {
        width: 100%;
      }
    }
  }
  .color__content {
    width: 100%;
    height: 50px;
  }
}

.colorFixed__overlow {
  overflow: auto;
  height: 100%;
}

.colorFixed__close {
  position: absolute;
  left: -33px;
  top: 0;
  background: #00aff0;
  color: #fff;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

/** Pull a color from the color map **/
@function get-color($key, $transparency: 0) {
  @if map-has-key($colors, $key) {
    @return transparentize(map-get($colors, $key), $transparency);
  }
  @error "Invalid color name: `#{$key}`.";
}
