/* scss/organisms/_textBlock.scss */

.textBlock{
  text-align: center;
  max-width: 1000px;
  margin: auto;
  padding: 55px 15px 40px;
  p{
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
  }
}

.textBlock__heading{
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
  color: #fff;
  margin-bottom: 10px;
}

.action{
  font-size: 21px;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  color: #ab602a;
  padding-bottom: 45px;
}


@media only screen and ( max-width: 693px ){
  .textBlock{
    padding: 55px 15px 40px;
    p{
      font-size: 16px;
    }
  }
  .textBlock__heading{
    font-size: 20px;
  }
  .action{
    font-size: 19px;
    padding-bottom: 30px;
  }
}