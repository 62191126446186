/* scss/organisms/_imgBlock.scss */

.imgBlock{
  padding-bottom: $base*2;
}

.imgBlock__inner{
  max-width: 1085px;
  margin: auto;
}

.imgBlock__item{
  @include col($four);
  text-align: center;
  padding: 0 15px;
  display: block;
  @media only screen and ( max-width: 693px ){
    padding: 0 15px;
    width: 100%;
  }
}

.imgBlock__title{
  font-size: 18px;
  font-weight: bold;
  margin-top: $base/2;
  text-transform: uppercase;
}

.socRow{
  text-align: center;
  margin-bottom: $base;
  a{
    margin: 0 2px;
  }
}

.legal{
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: $base*2;
  a{
    color: #fff;
  }
}