/* scss/siteForms/_form-input.scss */

.form-input {
  height: 50px;
  border-radius: 0;
  padding-left: 10px;
  width: 100%;
  background: #fff;
  line-height: 50px;
  border: 1px solid #ccc;
  padding-top: 0;
  transition: all 0.1s linear;
  &:focus {
    border: none;
  }
}