/*scss/molecules/_navMain.scss*/

/*
.navMain{
  list-style:none;
  padding:0;
  width: 100%;
  >li{
     display: inline-block;
     text-align: center;
     >a{
        padding:13px 20px;
        display:block;
        font-size: 15px;
        text-transform: uppercase;
        color: $color4;
      }
  }
}
.dropit-submenu{
  text-align:left;
  width:100%;
  a{
    padding:10px;
    display:block;
    background:#ccc;
    border-bottom:2px solid #fff;
  }
}*/


/*scss/molecules/_navMain.scss*/


.navMain {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
  position: relative;
  top: -50px;
  > li {
    display: inline-block;
    text-align: center;
    > a {
      padding: 0 30px;
      display: block;
      font-size: 18px;
      text-transform: uppercase;
      color: #fff;
    }
  }
}



.navMain_trigger{
  background: #ab602b;
  color: white;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px !important;
  border-radius: 50%;
  cursor: pointer;
  display: none !important;
  z-index: 2000;
  position: absolute;
  right: 20px;
  top: 20px;
}


@media only screen and (max-width: 873px) {
  .navMain {
    > li {
      > a {
        padding: 0 15px;
      }
    }
  }
}

@media only screen and (max-width: 693px) {
  .navMain_trigger{
    display: inline-block !important;
  }
  .navMain {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 400px;
    min-width: 300px;
    z-index: 1000;
    background: #000;
    box-shadow: -5px 0px 8px 0px rgba(0,0,0,0.09);
    padding-top: 75px;
    transform: translate(110%, 0);
    transition: transform .25s;
    > li {
      display: block;
      text-align: right;
      > a{
        padding-top: 15px;
        padding-right: 28px;
      }
    }
  }

  .navMain-open{
    .navMain {
      transform: translate(0, 0);
      transition: transform .25s;
    }
  }

}






