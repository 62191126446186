/* scss/misc/_cleanup.scss */

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body{
  font-family: $font1;
  color: #fff;
  font-size: 16px;
  background: #000000;
}
img{
  max-width:100%;
}
hr{
  border:1px solid #ccc;
}

a:hover, a:focus{
  text-decoration: none;
}

.pageWrap{
  max-width: 1296px;
  margin: auto;
}



.google-maps {
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
}
.google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}


img.alignright { float: right; margin: 0 0 1em 1em; }
img.alignleft { float: left; margin: 0 1em 1em 0; }
img.aligncenter { display: block; margin-left: auto; margin-right: auto; }
.alignright { float: right; }
.alignleft { float: left; }
.aligncenter { display: block; margin-left: auto; margin-right: auto; }