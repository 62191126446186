/* scss/siteFonts/_fonts.scss */

$font1: 'Trebuchet MS', sans-serif;
$font2: 'Trebuchet MS', sans-serif;


.font1{
	font-family: $font1;
}
.font2{
	font-family: $font2;
}

.font{
	span{
		display:block;
	}
}