/*scss/variables/_fonts.scss*/

$font1: 'Montserrat', sans-serif;
$font2: 'Open Sans', sans-serif;

.font1{
	font-family: $font1;
}
.font2{
	font-family: $font2;
	font-weight: 300;
}

.font{
	span{
		display:block;
	}
}