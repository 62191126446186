
[class*="block-grid-"] {
  display: block;
  /*margin: -($grid-gutter-width/2);*/
  padding: 0;
  @extend .grid-row;
}
 
.block-grid-item {
  display: inline;
  margin: 0;
  padding: ($grid-gutter-width/2);
	padding-left:0; /*added by me*/
  height: auto;
  float: left;
  /*list-style: none;*/
}
 
@mixin block-grid ($per-row) {
  & > .block-grid-item {
    width: (100%/$per-row);
 
    $nth-equation: #{$per-row}n+'+'+1;
    &:nth-of-type(n) { clear: none; }
    &:nth-of-type(#{$nth-equation}) { clear: both; }
  }
}
 
@mixin block-grids($size) {
  .block-grid-#{$size}-1  { @include block-grid(1);  }
  .block-grid-#{$size}-2  { @include block-grid(2);  }
  .block-grid-#{$size}-3  { @include block-grid(3);  }
  .block-grid-#{$size}-4  { @include block-grid(4);  }
  .block-grid-#{$size}-5  { @include block-grid(5);  }
  .block-grid-#{$size}-6  { @include block-grid(6);  }
  .block-grid-#{$size}-7  { @include block-grid(7);  }
  .block-grid-#{$size}-8  { @include block-grid(8);  }
  .block-grid-#{$size}-9  { @include block-grid(9);  }
  .block-grid-#{$size}-10 { @include block-grid(10); }
  .block-grid-#{$size}-11 { @include block-grid(11); }
  .block-grid-#{$size}-12 { @include block-grid(12); }
}
 
@include block-grids(xs); 
@media (min-width: $screen-sm-min) { @include block-grids(sm) }
@media (min-width: $screen-md-min) { @include block-grids(md) }
@media (min-width: $screen-lg-min) { @include block-grids(lg) }






.block-grid-example{
	@extend .grid-row;
	@include block-grid(4);
	.block-grid-example-item{
		@extend .block-grid-item;
		position:relative;
	}

	@media (max-width: 715px){ 
		@include block-grid(2);
	}
	@media (max-width: 427px){ 
		@include block-grid(1);
	}
}

