.btns {
  display: inline-block;
  background: none;
  padding: 15px 30px;
  background: #515151;
  color: #fff;
  opacity: 1;
  cursor: pointer;
  transition: all .25s;
  position: relative;
  font-size: 12px;
  text-align: center;
  border-radius: 0;
  border: none;
  font-family: $font2;
  &:hover{
    text-decoration: none;
    color: #fff;
  }
  &:active{
    text-decoration: none;
    color: #fff;
  }
}




.btns-1 {
  background: map-get($colors, color11);
  &:hover, &:active, &:focus{
    background: darken(map-get($colors, color11), 5%);
    color: #fff;
  }
}


.btns-2 {
  background: map-get($colors, color2);
  color: map-get($colors, color4);
  &:hover, &:active, &:focus{
    background: darken(map-get($colors, color2), 7%);
    color: #fff;
  }
}

.btns-3 {
  background: #fff;
  color: map-get($colors, color11);
  border: 1px solid map-get($colors, color11);
  padding: 14px 29px;
  &:focus{
    background: #fff;
    color: map-get($colors, color11);
    border: 1px solid map-get($colors, color11);
  }
  &:hover, &:active{
    color: #fff;
    background: map-get($colors, color11);
    border: 1px solid map-get($colors, color11);
  }
}


.btns-4 {
  background: #fff;
  color: map-get($colors, color4);
  border: 1px solid map-get($colors, color2);
  padding: 14px 29px;
  &:focus{
    background: #fff;
    color: map-get($colors, color4);
    border: 1px solid map-get($colors, color2);
    padding: 14px 29px;
  }
  &:hover{
    color: lighten(map-get($colors, color11), 20%) ;
    border: 1px solid lighten(map-get($colors, color11), 20%);
  }
  &:active{
    color: #fff;
    background: lighten(map-get($colors, color11), 20%) ;
    border: 1px solid lighten(map-get($colors, color11), 20%);
  }
}