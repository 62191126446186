.h1, h1, h2, .h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
  margin-top: 0;
  margin-bottom: $base*1.5;
  font-family: $font2;
  line-height: 1;
  color: map-get($colors, color7);
}


.h1, h1 {
  font-size: 60px;
  font-weight: 400;
}


.h2, h2 {
  font-size: 48px;
  font-weight: 400;
}

.h3, h3 {
  font-size: 30px;
  font-weight: 400;
}

.h4, h4 {
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
}

.h5, h5 {
  font-size: 16px;
  font-weight: 300;
}

.h6, h6 {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
}


.headingTitle{
  border-bottom: 1px solid #ccc;
}